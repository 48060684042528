[data-bs-theme=dark] {
    --main-bg: #121212;
}
body {
    color: var(--main-theme-color);
    background-color: var(--main-bg);
}

.main-bg {
    background-color: var(--main-bg);
}

.main-theme-color {
    color: var(--main-theme-color);
}

.main-theme-color2 {
    color: var(--main-theme-color2);
}
a {
    color: var(--main-theme-color);
}
a:hover {
    color: #717ff5;
}
h1,
h2,
h3,
h4,
h5,
h6 {
    color: var(--main-theme-color2);
}
.back-to-top {
    background: var(--main-theme-color);
}
.back-to-top:hover {
    background: #6776f4;
    color: var(--bs-body-bg);
}

.dropdown-menu .dropdown-footer a {
    color: var(--main-theme-color);
}

.dropdown-menu .dropdown-divider {
    color: #a5c5fe;
}

.dropdown-menu .dropdown-item:hover {
    background-color: var(--bs-body-bg);
}

.dropdown-menu-arrow::before {
    background: var(--bs-body-bg);
}

@keyframes dropdown-animate {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }

    0% {
        opacity: 0;
    }
}


/* Light Backgrounds */
.bg-primary-light {
    background-color: #cfe2ff;
    border-color: #cfe2ff;
}

.bg-secondary-light {
    background-color: #e2e3e5;
    border-color: #e2e3e5;
}

.bg-success-light {
    background-color: #d1e7dd;
    border-color: #d1e7dd;
}

.bg-danger-light {
    background-color: #f8d7da;
    border-color: #f8d7da;
}

.bg-warning-light {
    background-color: #fff3cd;
    border-color: #fff3cd;
}

.bg-info-light {
    background-color: #cff4fc;
    border-color: #cff4fc;
}

.bg-dark-light {
    background-color: #d3d3d4;
    border-color: #d3d3d4;
}
[data-bs-theme="dark"] {
    .card {
        box-shadow: none;
    }
}
.card-header,
.card-footer {
    border-color: #ebeef4;
    background-color: var(--bs-body-bg);
    color: #798eb3;
}

.card-title {
    color: var(--bs-card-title-color);
}

.card-title span {
    color: #899bbd;
}

.card-img-overlay {
    background-color: rgba(255, 255, 255, 0.6);
}

.alert-heading {
    color: #000;
}

.btn-close {
    background-size: 25%;
}
.accordion-button:not(.collapsed) {
    color: var(--main-theme-color2);
    background-color: var(--bs-body-bg);
}

.accordion-flush .accordion-button:not(.collapsed) {
    color: var(--main-theme-color);
}

.accordion-flush .accordion-body {
    color: #3e4f6f;
}

.breadcrumb {
    color: #899bbd;
}

.breadcrumb a {
    color: #899bbd;
}

.breadcrumb a:hover {
    color: #51678f;
}

.breadcrumb .breadcrumb-item::before {
    color: #899bbd;
}

.breadcrumb .active {
    color: #51678f;
}

.nav-tabs-bordered .nav-link {
    color: var(--bs-body-color);
}

.nav-tabs-bordered .nav-link:hover,
.nav-tabs-bordered .nav-link:focus {
    color: var(--main-theme-color);
}

.nav-tabs-bordered .nav-link.active {
    background-color: var(--bs-nav-tabs-border-color);
    color: var(--bs-body-color);
}

.logo span {
    color: var(--main-theme-color2);
}

.header {
    background-color: var(--bs-body-bg);
}

.header .toggle-sidebar-btn {
    color: var(--main-theme-color2);
}

.header .search-bar {
    box-shadow: 0px 0px 15px 0px rgba(1, 41, 112, 0.1);
    background: white;
}

.header .search-form input {
    color: var(--main-theme-color2);
    border: 1px solid rgba(1, 41, 112, 0.2);
}

.header .search-form input:focus,
.header .search-form input:hover {
    box-shadow: 0 0 10px 0 rgba(1, 41, 112, 0.15);
    border: 1px solid rgba(1, 41, 112, 0.3);
}

.header .search-form button i {
    color: var(--main-theme-color2);
}
.header-nav .nav-icon {
    color: var(--main-theme-color2);
}

.header-nav .nav-profile {
    color: var(--main-theme-color2);
}

.header-nav .notifications .notification-item p {
    color: #919191;
}

.header-nav .messages .message-item h4 {
    color: var(--main-theme-color);
}
.header-nav .messages .message-item p {
    color: #919191;
}

.header-nav .messages .message-item:hover {
    background-color: var(--bs-body-bg);
}

.header-nav .profile .dropdown-header h6 {
    color: var(--main-theme-color);
}

.header-nav .profile .dropdown-item:hover {
    background-color: var(--bs-body-bg);
}
.sidebar {
    background-color: var(--bs-body-bg);
}

.sidebar::-webkit-scrollbar {
    background-color: var(--bs-body-bg);
}

.sidebar::-webkit-scrollbar-thumb {
    background-color: #aab7cf;
}

.sidebar-nav .nav-heading {
    color: #899bbd;
}

.sidebar-nav .nav-link {
    color: var(--main-theme-color);
    background: var(--bs-body-bg);
}

.sidebar-nav .nav-link i {
    color: #899bbd;
}

.sidebar-nav .nav-link.collapsed {
    color: var(--main-theme-color);
    background: var(--bs-body-bg);
}

.sidebar-nav .nav-link.collapsed i {
    color: #899bbd;
}

.sidebar-nav .nav-link:hover {
    color: var(--bs-body-bg);
    background: var(--main-theme-color);
}

.sidebar-nav .nav-link:hover i {
    color: var(--bs-body-bg);
}

.sidebar-nav .nav-content a {
    color: var(--main-theme-color2);
}

.sidebar-nav .nav-content a:hover,
.sidebar-nav .nav-content a.active {
    color: var(--main-theme-color);
}

.sidebar-nav .nav-content a.active i {
    background-color: var(--main-theme-color);
}

.dashboard .filter .icon {
    color: #aab7cf;
}

.dashboard .filter .icon:hover,
.dashboard .filter .icon:focus {
    color: var(--main-theme-color);
}

.dashboard .filter .dropdown-header h6 {
    color: #aab7cf;
}

.dashboard .info-card h6 {
    color: var(--main-theme-color2);
}

.dashboard .sales-card .card-icon {
    color: var(--main-theme-color);
    background: #f6f6fe;
}

.dashboard .revenue-card .card-icon {
    color: #2eca6a;
    background: #e0f8e9;
}

.dashboard .customers-card .card-icon {
    color: #ff771d;
    background: #ffecdf;
}
.dashboard .activity .activity-label {
    color: #888;
}

.dashboard .news h4 a {
    color: var(--main-theme-color2);
}

.dashboard .news h4 a:hover {
    color: var(--main-theme-color);
}

.dashboard .news p {
    color: #777777;
}
.iconslist .icon {
    color: var(--main-theme-color2);
    background-color: var(--bs-card-bg);
}

.iconslist .label {
    color: var(--main-theme-color);
}

.profile .profile-card h2 {
    color: var(--main-theme-color);
}

.profile .profile-card .social-links a {
    color: var(--main-theme-color);
}

.profile .profile-card .social-links a:hover,
.profile .profile-overview .card-title {
    color: var(--main-theme-color2);
}

.profile .profile-edit label,
.profile .profile-overview .label {
    color: var(--main-theme-color2);
}

.faq .basic h6 {
    color: var(--main-theme-color);
}

.faq .basic p {
    color: #6980aa;
}

.contact .info-box i {
    color: var(--main-theme-color);
}

.contact .info-box h3 {
    color: var(--main-theme-color2);
}

.contact .php-email-form .error-message {
    color: var(--bs-body-bg);
    background: #ed3c0d;
}

.contact .php-email-form .sent-message {
    color: var(--bs-body-bg);
    background: #18d26e;
}

.contact .php-email-form input:focus,
.contact .php-email-form textarea:focus {
    border-color: var(--main-theme-color);
}

.contact .php-email-form button[type=submit] {
    background: var(--main-theme-color);
    color: var(--bs-body-bg);
}

.error-404 h1 {
    color: var(--main-theme-color);
}

.error-404 h2 {
    color: var(--main-theme-color2);
}

.error-404 .btn {
    background: var(--bs-body-bg);
    color: var(--main-theme-color);
}

.footer .copyright,
.footer .credits {
    color: var(--main-theme-color2);
}

.error-404 .btn:hover {
    background: #3e4f6f;
}
.pagetitle h1 {
    color: var(--main-theme-color2);
}
